<template>
  <div class="category-container">
    <!-- 导航栏 -->
    <van-row type="flex" align="center">
      <van-col span="2">
        <van-nav-bar left-arrow @click-left="onClickLeft"></van-nav-bar>
      </van-col>
      <van-col span="22">
        <van-search show-action shape="round" placeholder="请输入搜索关键词">
          <template #action>
            <van-button type="primary" round size="small">搜索</van-button>
          </template>
        </van-search>
      </van-col>
    </van-row>

    <div class="productList">
      <van-row type="flex" justify="space-start" :gutter="10">
        <van-col class="product" v-for="item in product" :key="item.id" span="12" @click="fn(item.id)">
          <van-image class="image" width="164" height="164" fit="cover" :src="host + item.iconPic">
            <template v-slot:loading>
              <van-loading vertical type="spinner" size="20" />
            </template>
          </van-image>
          <span class="cateName">{{ item.cateName }}</span>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { category } from '@/api/common';
export default {
  name: 'Category',
  data() {
    return {
      host: 'http://yongmie.top/category/',
      // host: 'http://8.138.152.95/category/',
      product: []
    };
  },
  created() {
    const { id } = this.$route.query;
    console.log(id, 'id');
    this.getProductInfo(id);
  },
  methods: {
    async getProductInfo(id) {
      try {
        const {
          data: { result }
        } = await category({ parentId: id });
        this.product = result;
      } catch (error) {
        console.log(error, 'error');
      }
    },
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
.category-container {
  padding-bottom: 50px;

  .productList {
    padding: 0 10px;

    .product {
      margin-bottom: 5px;

      .image {
        border: 1px solid #e6e6e6;
        border-radius: 3px;
      }

      .cateName {
        margin-top: 7px;
        font-size: 12px;
        line-height: 16px;
        color: #000;
        font-weight: 700;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }
}
</style>
